import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { Loadable } from '../../../components'
import { VerticalSteps } from './VerticalSteps'
import { TypeOfImports } from './TypeOfImports'
import ImportFileTable from './ImportFileTable'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_IMPORT_FILE_TABLE } from './graphqlCommon'
import { useUploadImport } from '../../../hooks/useUploadImport'
import { useBulkStatusSummary } from '../../../hooks/useBulkStatusSummary'
import { renderSwitch, DownloadButton, BigFileError, defaultSkipUpdateFields, UploadError, OpenBulkUpload } from './ImportElements'

/**
 * A ready to review import allows the user to see a preview of what is about to be imported,
 * select what type of upload to perform (overwrite or merge) and download the file.
 * @param {{customProps: object, setShowReward: function}} props
 * @returns {React.ReactHTML}
 */
export const ReadyToReviewContent = ({ customProps, setShowReward }) => {
  const { id: channelImportId, status, importFileUrl, tableId, loadedIntoSD, slug, instance, legacyJobId } = customProps || {}
  const [refetchData, setRefetch] = useState(false)
  const [typeOfImport, setTypeOfImport] = useState()
  const [showBulkUplaod, setShowBulkUplaod] = useState()
  const [avoidRefetchBSS, setAvoidRefetchBSS] = useState()
  const [skipUpdateFields, setSkipUpdateFields] = useState(defaultSkipUpdateFields)
  const fieldsToBeOverwritten = typeOfImport === 'overwrite' ? 'all' : skipUpdateFields

  const [getImportFileTable, { loading: loadingFile, error: errorFile, data: dataFile }] = useLazyQuery(
    GET_IMPORT_FILE_TABLE,
    {
      fetchPolicy: 'no-cache',
      variables: { tableId, start: 0, limit: 101 },
      onCompleted: () => { setRefetch(TableRows.length === 0 || Object.keys(TableRows).length === 0) }
    })
  const { Table } = dataFile || { Table: {} }
  const { TableRows } = dataFile || { TableRows: {} }
  const { columns: tableHeaders } = Table

  const [getBulkStatusSummary, { data: dataBSSummary, loading: loadingBSSummary, error: errorBSSummary, called: calledBSSummary }] = useBulkStatusSummary({ legacyJobId, avoidRefetchBSS, setAvoidRefetchBSS })
  const { BulkStatusSummary } = dataBSSummary || {}
  const { status: bulkStatus } = BulkStatusSummary || {}
  const uploadEnded = bulkStatus === 'complete'

  const [uploadImport, { data: dataUpload, loading: loadingUpload, error: errorUpload }] = useUploadImport({ channelImportId, typeOfImport, skipUpdateFields, legacyJobId, avoidRefetchBSS, getBulkStatusSummary })
  const uploadErrorAsData = dataUpload?.UploadImport?.uploadError

  const uploadStarted = calledBSSummary || loadingBSSummary
  const showBigFileAlert = errorUpload?.message === 'Network error: Unexpected end of JSON input'

  useEffect(() => {
    getImportFileTable()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (refetchData) {
      const timer = setTimeout(() => { getImportFileTable() }, 30000) // This will run after 30 seconds
      return () => clearTimeout(timer)
    }
  }, [refetchData, getImportFileTable, dataFile])

  useEffect(() => {
    if (legacyJobId && !avoidRefetchBSS) { getBulkStatusSummary() }
  }, [status, getBulkStatusSummary, legacyJobId, avoidRefetchBSS])

  return (
    <Loadable
      inline
      loading={refetchData}
      style={{ paddingTop: '1rem' }}
      content={
        <>
          <VerticalSteps importStatus={status} uploadStarted={uploadStarted}>
            {!uploadStarted && (
              <>
                {TableRows && <b className='preview-label'>Here is a sample of the items found in your store:</b>}
                <ImportFileTable
                  error={errorFile}
                  tableId={tableId}
                  loading={loadingFile}
                  tableData={TableRows}
                  tableHeaders={tableHeaders}
                />
              </>
            )}

            {!loadingFile && (
              <>
                <Row>
                  <Col xs={12}>
                    <h5 className='d-block mt-5 mb-4 text-center'>{uploadEnded ? 'Your upload has been successfully completed!' : 'Ready to load the data into your SureDone account?'}</h5>
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col xs={12} md={6} className='p-2 text-center'>
                    <DownloadButton importFileUrl={importFileUrl} slug={slug} instance={instance} />
                  </Col>
                  {!uploadStarted &&
                    (
                      <>
                        <Col xs={12} md={6} className='p-2 text-center border-left'>
                          <OpenBulkUpload setShowBulkUplaod={setShowBulkUplaod} />
                        </Col>
                      </>
                    )}
                </Row>

                {showBulkUplaod && !uploadStarted && (
                  <>
                    <b className='d-block text-center mt-4 mb-3 import-type-title'>How do you want to upload products to SureDone?</b>
                    <TypeOfImports
                      tableHeaders={tableHeaders}
                      typeOfImport={typeOfImport}
                      setTypeOfImport={setTypeOfImport}
                      skipUpdateFields={skipUpdateFields}
                      setSkipUpdateFields={setSkipUpdateFields}
                    />
                  </>
                )}

                {(typeOfImport || uploadStarted) &&
                  <Row className='align-items-end justify-content-center flex-wrap'>
                    <Col xs={12} sm={6} className='text-center p-2'>
                      {renderSwitch({ calledBSSummary, bulkStatus, channelImportId, setShowReward, slug, instance, loadingBSSummary, errorBSSummary, dataBSSummary, avoidRefetchBSS, uploadImport, uploadStarted, loadingUpload, errorUpload, loadedIntoSD, fieldsToBeOverwritten })}
                    </Col>
                  </Row>}
              </>
            )}
          </VerticalSteps>

          {showBigFileAlert && <BigFileError />}
          {(errorUpload || uploadErrorAsData) && <UploadError error={errorUpload || uploadErrorAsData} />}
        </>
      }
    />
  )
}
