import React, { useEffect } from 'react'
import { FormGroup, FormText } from 'reactstrap'
import { ActionButton } from '../../../components'
import { FaCubes as Products } from 'react-icons/all'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_ONBOARD_DATA, UPDATE_ONBOARD_DATA } from '../../Dashboard/commonQueries'

export const ManageProductsButton = ({ channelImportId, setShowReward, slug, instance }) => {
  const { data: onboardData } = useQuery(GET_ONBOARD_DATA, { fetchPolicy: 'no-cache' })
  const { OnboardData } = onboardData || {}
  const { confettiWasShownIn } = OnboardData || {}
  const [updateOnboardData] = useMutation(UPDATE_ONBOARD_DATA)
  const confettiId = `import-${channelImportId}`

  useEffect(() => {
    if (!confettiWasShownIn || confettiWasShownIn?.includes(confettiId)) return // confetti was already shown here
    setShowReward(true)
    if (confettiWasShownIn?.length > 0) {
      updateOnboardData({ variables: { input: { confettiWasShownIn: [confettiId, ...confettiWasShownIn] } } }) // add this place to the confetti shown list
    } else if (!confettiWasShownIn?.includes(confettiId)) {
      updateOnboardData({ variables: { input: { confettiWasShownIn: [confettiId] } } }) // in case confettiWasShownIn array is empty
    }
  }, [onboardData, confettiId, confettiWasShownIn, setShowReward, updateOnboardData])

  const handleClick = () => {
    if (window.analytics) {
      const currentEvent = 'Manage Products'
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'User clicks Manage Products',
        channel: slug,
        instance: instance > 0 ? instance : '',
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
  }

  return (
    <FormGroup>
      <h6>Manage the uploaded items</h6>
      <ActionButton onClick={() => handleClick()} tag='a' href='/#!/products' color='link' icon={<Products />} className='link-outline'>
        Manage products
      </ActionButton>
      <FormText>
        Your upload has been successfully completed and you are now able to manage all these products.
      </FormText>
    </FormGroup>
  )
}
