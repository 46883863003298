import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { Field } from 'react-final-form'
import { BulkProgress } from './BulkProgress'
import { VerticalSteps } from './VerticalSteps'
import { people } from '../../../img/illustrations'
import { useStores } from '../../../stores/RootStore'
import { ManageProductsButton } from './ManageProductsButton'
import { useBulkStatusSummary } from '../../../hooks/useBulkStatusSummary'
import { ActionButton, EmptyState, ErrorAlert, SaveButton } from '../../../components'
import { FaFileCsv as ImportDownload, FaUpload as ImportUpload, FiCheckCircle as Check, FaAngleDown, AiOutlineQuestionCircle as Info } from 'react-icons/all'
import { Alert, Button, CardText, Col, FormFeedback, FormGroup, FormText, Input, Label, Progress, Row, UncontrolledCollapse, Badge, Card, CardHeader, CardBody, UncontrolledTooltip, Spinner } from 'reactstrap'
import { isAmazon } from 'suredone-common'

const { noLogs } = people
const appEndpoint = window.serverConfig.legacyAppEndpoint

export const MAP_IMPORT_STATES = {
  IN_PROGRESS: 'In progress',
  READY_TO_REVIEW: 'Ready to review'
}

export const IMPORT_STATES_TO_COLOR = {
  IN_PROGRESS: 'info',
  READY_TO_REVIEW: 'warning'
}

export const INDEX_IMPORT_STATES = ['IN_PROGRESS', 'READY_TO_REVIEW']

export const MILLISECONDS_TO_REFRESH_BSS = 30000 // every 30 seconds bulk satus summary will be recalled and refreshed

export const DownloadButton = ({ importFileUrl, slug, instance }) => {
  const handleClick = () => {
    if (window.analytics) {
      const currentEvent = `${slug} ${instance}: Download import`
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'User clicks on download import',
        instanceNumber: instance,
        slug: `${slug}${instance > 0 ? instance : ''}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
  }
  return (
    <FormGroup>
      <h6>Download File</h6>
      <p>You can download the raw import file for editing and processing on your own.</p>
      <ActionButton
        tag='a'
        color='link'
        loading={false}
        disabled={false}
        href={importFileUrl}
        className='link-outline'
        onClick={() => handleClick()}
      >
        <ImportDownload /> Download Import File
      </ActionButton>
      <FormText>
        Use our <a href={`${appEndpoint}/#!/bulk/uploads`} target='_blank' rel='noopener noreferrer'>bulk upload feature</a> to upload the file afterwards
      </FormText>
    </FormGroup>
  )
}

export const OpenBulkUpload = ({ setShowBulkUplaod }) => {
  const handleClick = () => setShowBulkUplaod(true)

  return (
    <>
      <h6>Upload directly to SureDone</h6>
      <p>Upload the file to SureDone right now without making any changes.</p>
      <ActionButton
        color='link'
        className='link-outline'
        onClick={() => handleClick()}
      >
        <ImportUpload className='mr-2' /> Upload Products to SureDone
      </ActionButton>
      <FormText>By clicking you will be able to configure how to load the products</FormText>
    </>
  )
}

export const BulkUploadButton = ({ uploadImport, loadingUpload, errorUpload, uploadStarted, loadedIntoSD, showHelpText = true, fieldsToBeOverwritten = null, color = 'primary', slug, instance, dataBSSummary }) => {
  const { status: BSStatus } = dataBSSummary || {}

  const handleClick = () => {
    if (window.analytics) {
      const currentEvent = 'Clicked load into account'
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        action: 'User clicks on load import into account',
        instanceNumber: instance,
        channel: `${slug}${instance}`,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
    uploadImport()
  }

  const generateButtonText = () => {
    if (loadingUpload) return 'Loading into your account'
    if (BSStatus === 'complete') return 'Loaded into your account'
    if (uploadStarted || loadedIntoSD) return 'Import to account started'
    return 'Finish Upload'
  }

  return (
    <FormGroup>
      <ActionButton
        color={color}
        onClick={() => handleClick()}
        loading={loadingUpload}
        disabled={uploadStarted || errorUpload || loadedIntoSD}
      >
        {!loadingUpload && ((uploadStarted || loadedIntoSD) ? <Check className='mr-2' /> : <ImportUpload className='mr-2' />)}
        {generateButtonText()}
      </ActionButton>
      {showHelpText &&
        <FormText>This button will load the imported products directly into your SureDone account.</FormText>}
      {fieldsToBeOverwritten === 'all' &&
        <FormText>
          <b>All fields will be overwritten.</b>
        </FormText>}
    </FormGroup>
  )
}

export const HowManyProducts = props => {
  const { channelName, parentCallback } = props
  const [selectedOption, setSelectedOption] = useState(true)
  const options = [
    { value: '50', label: '1 - 100' },
    { value: '1000', label: '101 - 1000' },
    { value: '10000', label: '1,001 - 10,000' },
    { value: '100000', label: '10,001 - 100,000' },
    { value: '1000000', label: '100,001 - 1,000,000' },
    { value: '1250000', label: '1,000,000 +' }
  ]

  const handleChange = (event) => {
    setSelectedOption(event)
    parentCallback(event)
  }

  return (
    <Row className='align-items-center'>
      <Col xs='auto'>
        <CardText>
          About how many products are in your {channelName} catalog?
        </CardText>
      </Col>
      <Col xs={12} sm={4} lg={3} xl={3} className='mt-2 mt-md-0'>
        <Select
          options={options}
          clearable={false}
          value={selectedOption}
          onChange={handleChange}
          placeholder=''
        />
      </Col>
    </Row>
  )
}

export const getDuration = (amountOfProducts, importRate) => {
  const { value: numberOfProducts } = amountOfProducts || {}
  return Math.max(1, numberOfProducts / importRate)
}

export const formatDuration = (estimation) => moment.duration(estimation, 'minutes').humanize()

export const infoMessage = (
  <Alert color='info'>
    <Row>
      <Col>
        <div className='my-auto'>
          You don't have products to import yet!
        </div>
      </Col>
    </Row>
  </Alert>
)

export const FullImportForm = ({ slug, loadingMutation, dataMutation, errorMutation, handleSubmit, hasValidationErrors, submitting, values }) => {
  return (
    <form className='form' onSubmit={handleSubmit}>
      <Row>
        <Col sm={12}>
          {slug !== 'ebay' && !isAmazon(slug) &&
            <Row>
              <Col>
                <FormGroup tag='fieldset' className='mb-0'>
                  <Button color='link' className='p-0 mt-n1 mb-1' id='advanced-options' style={{ marginBottom: '1rem' }}>
                    Advanced options <FaAngleDown size={14} />
                  </Button>
                  <UncontrolledCollapse toggler='#advanced-options' className='px-0 w-100'>
                    <Label tag='legend'>Import Action</Label>
                    <FormGroup check className='pl-0'>
                      <Field
                        name='importAction'
                        value='match'
                        type='radio'
                      >
                        {({ input, meta }) => (
                          <div className='custom-control custom-radio mb-1'>
                            <Label>
                              <Input
                                {...input}
                                id='match'
                                type='radio'
                                className='custom-control-input'
                              />
                              <span className='custom-control-label'>Match</span>
                              <div className='text-muted'> Generates import with actions for creating and updating products based on SKU, UPC or channel identifier. </div>
                            </Label>
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup check className='pl-0'>
                      <Field
                        name='importAction'
                        value='create'
                        type='radio'
                      >
                        {({ input, meta }) => (
                          <div className='custom-control custom-radio mb-1'>
                            <Label>
                              <Input
                                {...input}
                                id='create'
                                type='radio'
                                className='custom-control-input'
                              />
                              <span className='custom-control-label'>Create</span>
                              <div className='text-muted'> Generates import with actions for creating products that do not exist based on the channel identifier. </div>
                            </Label>
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup check className='pl-0'>
                      <Field
                        name='importAction'
                        value='update'
                        type='radio'
                      >
                        {({ input, meta }) => (
                          <div className='custom-control custom-radio mb-1'>
                            <Label>
                              <Input
                                {...input}
                                id='update'
                                type='radio'
                                className='custom-control-input'
                              />
                              <span className='custom-control-label'>Update</span>
                              <div className='text-muted'> Generates import with actions for creating and updating products based on the channel identifier. </div>
                            </Label>
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                    <FormGroup check className='pl-0'>
                      <Field
                        name='importAction'
                        value='draft'
                        type='radio'
                      >
                        {({ input, meta }) => (
                          <div className='custom-control custom-radio mb-1'>
                            <Label>
                              <Input
                                {...input}
                                id='draft'
                                type='radio'
                                className='custom-control-input'
                              />
                              <span className='custom-control-label'>Draft</span>
                              <div className='text-muted'> Generates import with actions for creating draft products. </div>
                            </Label>
                          </div>
                        )}
                      </Field>
                    </FormGroup>
                  </UncontrolledCollapse>
                </FormGroup>
              </Col>
            </Row>}
          <Row>
            <Col>
              <Label tag='legend' className='my-2' for='Business'>Notification email</Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name='email'
                validate={email => email ? undefined : 'Notification email is required'}
              >
                {({ input, meta }) => (
                  <FormGroup className='mb-2'>
                    <Input
                      {...input}
                      type='email'
                      invalid={!meta.pristine && meta.touched && meta.invalid}
                    />
                    <FormText>
                      We are going to let you know once your import file is ready by using this email address.
                    </FormText>
                    {meta.error && !meta.pristine && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                  </FormGroup>
                )}
              </Field>
            </Col>
          </Row>
          <FormGroup className='float-left mt-3'>
            <SaveButton
              color='primary'
              // className='mb-3'
              type='submit'
              disabled={loadingMutation || submitting || hasValidationErrors || dataMutation || errorMutation}
              loading={loadingMutation || submitting}
            >
              Import all products
            </SaveButton>
          </FormGroup>
        </Col>
      </Row>
    </form>
  )
}

export const SampleImportForm = ({ loadingMutation, dataMutation, errorMutation, handleSubmit, hasValidationErrors, submitting, values }) => {
  return (
    <form className='form' onSubmit={handleSubmit}>
      <Row>
        <Col sm={12}>
          <FormGroup className='float-left my-0'>
            <SaveButton
              className='p-0'
              color='link'
              type='submit'
              disabled={loadingMutation || submitting || hasValidationErrors || dataMutation || errorMutation}
              loading={loadingMutation || submitting}
            >
              Start sample import
            </SaveButton>
            <span id='sample-info' className='text-muted'><Info className='ml-2 mt-1' /></span>
            <UncontrolledTooltip placement='right' target='sample-info'>Perform a sample import for your account.</UncontrolledTooltip>
          </FormGroup>
        </Col>
      </Row>
    </form>
  )
}

const getFinishDate = (startDate, estimatedTime) => {
  const finishDate = new Date(startDate)
  finishDate.setMinutes(finishDate.getMinutes() + estimatedTime)
  return finishDate
}

export const ImportProgress = props => {
  const { startDate, estimatedTime, status } = props
  const { stores: { auth } } = useStores()
  const [now, setNow] = useState(new Date())
  const finishDate = getFinishDate(startDate, estimatedTime)
  const MAX_PERCENTAGE = 99
  const MIN_PERCENTAGE = 0

  const minutesToGo = Math.round(finishDate - now) / 60000
  const progressInMinutes = estimatedTime - minutesToGo
  const progressInPercentage = Math.round(progressInMinutes * 100 / estimatedTime)
  const alreadyFinished = (finishDate - now) < 0
  const value = alreadyFinished ? MAX_PERCENTAGE : progressInPercentage
  const limitedValue = value < 0 ? MIN_PERCENTAGE : value
  const isInProgress = status === INDEX_IMPORT_STATES[0]

  // Add this to make the effect of real time progress until we have real progress data.
  // Specially usefull for small imports
  useEffect(() => {
    let timer
    if (value === MAX_PERCENTAGE && isInProgress) {
      timer = setInterval(() => { // MAX_PERCENTAGE has been reached but the status is still in progress. Forces an update after 1 minute
        auth.refresh()
      }, 60000) // 1 minute
    } else {
      timer = setInterval(() => { // The MAX_PERCENTAGE has not been reached but we want to update the progress bar so we'll update 'now' status every 5 seconds.
        setNow(new Date())
      }, 5000) // 5 seconds
    }
    return () => clearInterval(timer)
  }, [value, isInProgress, auth])

  return <Progress className='mt-3 mb-n2' animated value={limitedValue}>{limitedValue}%</Progress>
}

export const UploadError = ({ error }) => <ErrorAlert color='danger' error={error?.message} />

export const BigFileError = () => {
  return (
    <ErrorAlert color='warning'>
      <>
        The import appears to be a very large file and may take several minutes to upload.
        Please check <a href='/#!/bulk/results'>bulk results</a> later for status updates.
      </>
    </ErrorAlert>
  )
}

/**
 * We have 3 states for an import: IN_PROGRESS, READY_TO_REVIEW and CANCELLED.
 * But for READY_TO_REVIEW the UI will show more states to help the user to understand
 * what is going on with imports (Uploading and Uploaded).
 * @param {{ status: string, uploadStarted: boolean, uploadError: gqlError, loadedIntoSD: boolean }} props
 * @returns {React.ReactHTML}
 */
export const StatusBadge = ({ status, uploadStarted, uploadEnded, uploadError }) => {
  const workingOnUpload = uploadError?.message?.includes('legacyJobId not set')

  const getColorAndText = status => {
    if (status === 'CANCELLED') return ['danger', 'Cancelled']
    if (status === 'IN_PROGRESS') return ['info', 'In progress']
    if (status === 'APPROVED') return ['success', 'Approved'] // to prevent old imports error
    if (status === 'READY_TO_REVIEW') {
      if (uploadEnded) return ['success', 'Uploaded']
      if (uploadStarted || workingOnUpload) return ['primary', 'Uploading']
      return ['warning', 'Ready']
    }
    return ['danger', 'Error']
  }
  const [color, text] = getColorAndText(status)

  return (
    <span className='small mt-0 ml-2'>
      <Badge color={color}>{text}</Badge>
    </span>
  )
}

/**
 * Import summary for cancelled imports (an import is usually cancelled when it doesn't have products to be imported)
 * @param {{idx: number, status: string}} props
 * @returns {React.ReactHTML}
 */
export const CancelledImport = ({ idx, status }) => {
  return (
    <Card>
      <CardHeader id={`cancelled-${idx}`} className='d-flex align-items-center justify-content-between' style={{ cursor: 'pointer' }}>
        <h5 className='d-flex align-items-center mb-0'>
          Empty import
          <StatusBadge status={status} />
        </h5>
        <FaAngleDown size={24} color='#bbb' className='mr-n1' />
      </CardHeader>

      <UncontrolledCollapse toggler={`cancelled-${idx}`}>
        <CardBody className='pt-0'>
          <Row className='mt-4 justify-content-center'>
            <EmptyState isEmpty customImage={noLogs} content='Looks like the import was empty, try adding some products.' />
          </Row>
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  )
}

export const defaultSkipUpdateFields = ['action', 'guid']

export const decideIfSendSkipUpdate = (typeOfImport, skipUpdateFields) => {
  if (typeOfImport === 'merge') {
    const noDefaultFields = skipUpdateFields?.filter(i => !defaultSkipUpdateFields.includes(i))
    return noDefaultFields
  }
  return null
}

export const renderSwitch = ({ calledBSSummary, bulkStatus, ...props }) => {
  if (calledBSSummary) { // The upload was started
    if (bulkStatus === 'failed') return <ErrorAlert error='Your upload failed, please try it again.' />
    if (bulkStatus === 'complete') return <ManageProductsButton {...props} />
    return <BulkProgress {...props} />
  } else {
    return <BulkUploadButton {...props} />
  }
}

export const ImportSummaryHeader = ({ customProps }) => {
  const { id: channelImportId, status, creationDate, importType, legacyJobId } = customProps || {}
  const [avoidRefetchBSS, setAvoidRefetchBSS] = useState()

  const [getBulkStatusSummary, { data: dataBSSummary, loading: loadingBSSummary, error: errorBSSummary, called: calledBSSummary }] = useBulkStatusSummary({ legacyJobId, avoidRefetchBSS, setAvoidRefetchBSS })
  const { BulkStatusSummary } = dataBSSummary || {}
  const { status: bulkStatus } = BulkStatusSummary || {}
  const uploadStarted = calledBSSummary || loadingBSSummary
  const uploadEnded = bulkStatus === 'complete'

  useEffect(() => {
    if (legacyJobId && !avoidRefetchBSS) { getBulkStatusSummary() }
  }, [getBulkStatusSummary, legacyJobId, avoidRefetchBSS])

  return (
    <CardHeader id={`id-${channelImportId}`} className='d-flex align-items-center justify-content-between' style={{ cursor: 'pointer' }}>
      <h5 className='d-flex align-items-center mb-0'>
        {importType} import
        {creationDate && <FormText className='mx-2'>{moment(Number(creationDate)).format('MMMM D, YYYY h:mma')}</FormText>}
        <StatusBadge
          status={status}
          uploadEnded={uploadEnded}
          uploadError={errorBSSummary}
          uploadStarted={uploadStarted}
        />
      </h5>
      <FaAngleDown size={24} color='#bbb' className='mr-n1' />
    </CardHeader>
  )
}

export const InProgressContent = ({ customProps }) => {
  const { status } = customProps || {}

  return (
    <VerticalSteps importStatus={status}>
      <Col xs={12}>
        <FormGroup>
          <Spinner color='secondary' size='sm' className='mr-2' />
          We are obtaining your products information. This may take some time depending on the size of your catalog.
        </FormGroup>
      </Col>
    </VerticalSteps>
  )
}
