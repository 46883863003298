import React, { useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { ImportField } from './ImportField'
import { FaCheckSquare, FaMinusSquare } from 'react-icons/fa'
import { ActionButton, VisualPicker } from '../../../components'
import { ResourceList } from '../../../components/SearchableLists'
import { defaultSkipUpdateFields } from './ImportElements'

const importTypes = [
  {
    id: 'overwrite',
    label: 'Overwrite',
    description: 'This option is ideal for new products that are not already in your catalog.'
  },
  {
    id: 'merge',
    label: 'Merge',
    description: 'Ideal if the products are already in your catalog. Only new fields that do not already exist will be imported.'
  }
]

export const TypeOfImports = ({ tableHeaders, typeOfImport, setTypeOfImport, skipUpdateFields, setSkipUpdateFields }) => {
  const mergeWasSelected = typeOfImport === 'merge'
  const overwriteWasSelected = typeOfImport === 'overwrite'
  const fields = tableHeaders?.map(({ name }) => ({ key: name }))
  const [showAllFields, setShowAllFields] = useState(false)
  const fieldsLimit = 30
  const fieldsToShow = showAllFields ? skipUpdateFields : skipUpdateFields.slice(0, fieldsLimit)

  const handleChangeValue = (event, field) => {
    const isChecked = event.target.checked

    if (isChecked) {
      setSkipUpdateFields(prev => [...prev, field.key])
    } else {
      setSkipUpdateFields(prev => prev.filter(i => i !== field.key))
    }
  }

  const handleSelectAll = (type) => {
    if (type === 'select') {
      setSkipUpdateFields(fields?.map(i => i.key))
    } else { // 'deselect'
      setSkipUpdateFields(defaultSkipUpdateFields)
    }
  }

  return (
    <>
      <Row className='d-flex justify-content-center type-of-import'>
        {importTypes.map(({ id, label, description }) =>
          <Col key={id} xs={12} sm={6} lg={6} xl={4} className='mb-2'>
            <VisualPicker
              id={id}
              name='type-of-import'
              isSelected={typeOfImport === id}
              parentCallback={() => setTypeOfImport(id)}
            >
              <h6 className='d-block text-center px-3 pt-3 pb-2 m-0'>{label}</h6>
              <p className='mb-0 px-3 pb-3 text-center text-muted'>
                {description}
              </p>
            </VisualPicker>
          </Col>
        )}
      </Row>

      {typeOfImport &&
        <>
          <Row className='my-3 justify-content-center'>
            {overwriteWasSelected &&
              <Col xs={12} md={11} className='text-center'>
                <p>
                  An <strong>Overwrite import</strong> will import all fields from your data, including <code>sku</code>, <code>title</code>, <code>price</code>, <code>description</code> and other <code>core fields</code>. <br />
                  If the <code>sku</code> on a product in the import matches one in your database, <b><u>this will overwrite the information in your database</u></b>.
                </p>
              </Col>}

            {mergeWasSelected &&
              <Col xs={12} md={11} className='text-center'>
                <p>
                  If the <code>sku</code> on a product matches one in your database, a <b>Merge</b> import will import channel specific fields from the file to that product. <br />
                </p>
              </Col>}
          </Row>

          {mergeWasSelected &&
            <Row>
              <Col xs={12} className='mb-n3'>
                <div className='d-flex flex-wrap px-3 py-3 mb-n1' style={{ boxShadow: '0 0 0 1px rgba(20, 20, 31, 0.05), 0 1px 3px 0 rgba(20, 20, 31, 0.15)', borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}>
                  <p className='w-100 mb-2'>Select which fields <b>should not</b> be overwritten:</p>
                  <ActionButton icon={<FaCheckSquare />} onClick={() => handleSelectAll('select')} className='mr-2'>Select all</ActionButton>
                  <ActionButton icon={<FaMinusSquare />} onClick={() => handleSelectAll('deselect')}>Deselect all</ActionButton>
                </div>
                <ResourceList
                  items={fields}
                  itemsPerPage={20}
                  className='import-fields'
                  searchableStrings={({ key }) => [key]}
                  itemComponent={
                    ({ item: field, searchTerms }) =>
                      <li className='list-group-item-flush d-flex flex-sm-row flex-column list-group-item-action list-group-item p-0'>
                        <ImportField
                          field={field}
                          searchTerms={searchTerms}
                          parentCallback={handleChangeValue}
                          skipUpdateFields={skipUpdateFields}
                        />
                      </li>
                  }
                />
              </Col>

              <Col xs={12}>
                These fields <b><u>will not be overwritten</u></b>: <br />
                {fieldsToShow.map(i => <code key={i}> {i} </code>)}
                {skipUpdateFields.length > fieldsLimit && (
                  <Button className='px-1 py-0' color='link' onClick={() => setShowAllFields(!showAllFields)}>
                    {showAllFields ? 'show lees fields' : 'show all fields...'}
                  </Button>
                )}
              </Col>
            </Row>}
        </>}
    </>
  )
}
