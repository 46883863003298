import React from 'react'
import { Col } from 'reactstrap'
import { INDEX_IMPORT_STATES } from './ImportElements'
import { FiCheckCircle as Checked, FiCircle as Unchecked } from 'react-icons/fi'

const index = status => INDEX_IMPORT_STATES.indexOf(status?.toUpperCase())
const titles = ['Getting your products', 'Review your products']

const Title = ({ checked, current, content, status }) => {
  return (
    <h6 className='w-100 mt-4 mb-3' style={{ color: checked || current > index(status) ? '#aaa' : 'unset' }}>
      {checked
        ? <Checked color='#aaa' size={16} className='mb-1 mr-1 ml-0' />
        : <Unchecked size={16} className='mb-1 mr-1 ml-0' />}
      {content}
    </h6>
  )
}

export const VerticalSteps = ({ importStatus, bulkStatus, uploadStarted, children }) => {
  const isLastStepAndBulkFinished = index(importStatus) === 1 && bulkStatus === 'complete'
  const forceChecked = uploadStarted || importStatus === 'APPROVED'

  return (
    // Steps 1 and 2
    <Col xs={12} className='d-block w-100'>
      {INDEX_IMPORT_STATES.map((_, idx) =>
        <div key={idx}>
          <Title
            current={idx}
            status={importStatus}
            content={`Step ${idx + 1}: ${titles[idx]}`}
            checked={index(importStatus) > idx || isLastStepAndBulkFinished || forceChecked}
          />
          {index(importStatus) === idx && children}
        </div>
      )}
      {/* Step 3 but only for old imports, with status === 'APPROVED' */}
      {importStatus === 'APPROVED' && (
        <div>
          <Title
            current='APPROVED'
            status={importStatus}
            checked={bulkStatus === 'complete'}
            content='Step 3: Apply import to account'
          />
          {children}
        </div>
      )}
    </Col>
  )
}
